import React from "react";
import { graphql } from "gatsby";
import Layout from "./Layout";
import styled from "styled-components";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderOne from "../sections/SliderOne";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import { Link } from "gatsby";
import BlogPostPreview from "../components/BlogPostPreview";
import DefaultButton from "../components/buttons/DefaultButton";
import { v4 as uuidv4 } from "uuid";
import { StaticImage } from "gatsby-plugin-image";

const StyledWpPost = styled.div`
  .blog-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wp-blog {
    &__title {
      min-height: 75px;
      &--yellow {
        color: #e7c700;
      }
    }

    &__filters {
      &--desktop {
        a {
          flex-basis: 25%;
          padding: 10px;
          display: grid;
        }
        button {
          width: 100%;
        }
      }
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default function WpPost({ data }) {
  const postsByCategory = data.postsByCategory.nodes;
  const allPosts = data.allPosts.nodes;
  const allCategories = data.allCategories.edges;
  const defaultTitle = "Il blog di PrestitiFaidaTe: tanti articoli per saperne di più su Cessione del quinto, prestiti, finanziamenti e mutui casa. Segui i consigli dei nostri esperti!";
  const pageTitle = postsByCategory.length > 0
    ? postsByCategory[0].categories.nodes[0].name + ":   per saperne di più - Prestitifaidate"
    : "Cessione del quinto, prestiti e mutui: il blog - Prestitifaidate";
  const pageDescription = postsByCategory.length
    ? (postsByCategory[0].categories.nodes[0].description ? postsByCategory[0].categories.nodes[0].description : defaultTitle) + " - Prestitifaidate"
    : defaultTitle;

  return (
    <Layout 
      helmetTitle={pageTitle}
      helmetDescription={pageDescription}
    >
      <StyledWpPost id="post-content">
        <div className="wp-blog__header my-5">
          <div className="blog-section container-desktop d-none d-lg-flex">
            <StaticImage
              src="../assets/images/blog-section-left.png"
              className="img-fluid"
              alt="Image"
              placeholder="blurred"
              layout="constrained"
              width={1458}
              height={534}
              objectFit="contain"
            />
            <div className="content text-center">
              <StaticImage
                src="../assets/images/blog-section-center.png"
                className="mx-auto"
                alt="Image"
                placeholder="blurred"
                layout="constrained"
                width={97}
                height={131}
                objectFit="contain"
              />
              <div className="content__title pb-2 fs-2">A Tutto Prestiti</div>
              <p className="content__subtitle">di Prestiti Fai da Te</p>
              <p className="content__description">
                Nel blog di PrestitiFaidaTe.it trovi tanti articoli per saperne
                di più su Cessione del quinto, prestiti, finanzimaenti e mutui
                casa. Segui i consigli dei nostri esperti!
              </p>
            </div>
            <StaticImage
              src="../assets/images/blog-section-right.png"
              className="img-fluid"
              alt="Image"
              placeholder="blurred"
              layout="constrained"
              width={567}
              height={558}
              objectFit="contain"
            />
          </div>

          <div className="blog-section container-mobile d-flex d-lg-none">
          <StaticImage
              src="../assets/images/testata-blog-mobile-scr.png"
              className="mx-auto"
              alt="Image"
              placeholder="blurred"
              layout="constrained"
              width={718}
              height={807}
              objectFit="contain"
              quality={75}
            />
      </div>

        </div>
        <div id="post-content" className="container mx-auto">
          <div className="wp-blog__slug mb-5 mt-4 fs-5">
            <Link to="/" className="fw-bold ">
              HOME
            </Link>{" "}
            &gt; <Link to="/blog/">Blog</Link>{" "}
            {postsByCategory.length > 0 && (
              <span>
                &gt;{" "}
                <Link
                  to={"/blog/" + postsByCategory[0].categories.nodes[0].slug + '/'}
                >
                  {" "}
                  {postsByCategory[0].categories.nodes[0].name}
                </Link>{" "}
              </span>
            )}
          </div>

          <h1 className="wp-blog__title text-center mb-5">
            {postsByCategory.length > 0 ? postsByCategory[0].categories.nodes[0].name + ":   per saperne di più": "Cessione del quinto, prestiti e mutui: il blog"}
          </h1>

          <div className="wp-blog__filters mt-3 mb-5">
            <div className="wp-blog__filters--desktop d-none d-lg-flex flex-wrap">
              <DefaultButton
                title="Tutto"
                className={`btn-outline-dark ${
                  postsByCategory.length ? "" : "active"
                }`}
                url={"/blog/"}
              />

              {allCategories.map((category) => {
                if (category.node.count > 0) {
                  return (
                    <DefaultButton
                      title={category.node.name}
                      className={`btn-outline-dark ${
                        postsByCategory.length &&
                        postsByCategory[0].categories.nodes[0].name ===
                          category.node.name
                          ? "active"
                          : ""
                      }`}
                      url={"/blog/" + category.node.slug + "/"}
                      key={uuidv4()}
                    />
                  );
                }
                return null;
              })}
            </div>
            <div className="wp-blog__filters--mobile d-block d-lg-none text-center">
              <button
                type="button"
                className="btn btn-outline-dark dropdown-toggle rounded-pill"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Seleziona categoria
              </button>
              <ul className="dropdown-menu">
                {allCategories.map((category) => {
                  if (category.node.count > 0) {
                    return (
                      <li key={uuidv4()}>
                        <a
                          className={`dropdown-item ${
                            postsByCategory.length &&
                            postsByCategory[0].categories.nodes[0].name ===
                              category.node.name
                              ? "active"
                              : ""
                          }`}
                          href={"/blog/" + category.node.slug + "/"}
                        >
                          {category.node.name}
                        </a>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          </div>

          <div className="row">
            {postsByCategory.map((post) => (
              <BlogPostPreview post={post} className="col-12 col-lg-4" key={uuidv4()} />
            ))}
            {!postsByCategory.length &&
              allPosts.map((post) => (
                <BlogPostPreview post={post} className="col-12 col-lg-4" key={uuidv4()} />
              ))}
          </div>
        </div>
        <StaticImage
              src="../assets/images/chiusura-blog.png"
              className="img-fluid"
              alt="Image"
              placeholder="blurred"
              layout="constrained"
              width={1920}
              height={252}
              objectFit="contain"
            />
      </StyledWpPost>
      <TargetFullBanner
        title="Dipendente pubblico, privato o pensionato?"
        description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
        className="mt-2"
      />
      <DipendentePensionatoPrivato />
      <SliderOne />
      <MetodoPiuVeloce className="mt-2" />
      <CessioneFinanziamenti className="mb-5" />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String) {
    postsByCategory: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        excerpt
        slug
        date
        categories {
          nodes {
            name
            id
            slug
            description
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 75
                  width: 512
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allPosts: allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        excerpt
        categories {
          nodes {
            name
            id
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 75
                  width: 512
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allCategories: allWpCategory {
      edges {
        node {
          slug
          name
          count
        }
      }
    }
  }
`;
